import React, { useState, useEffect } from 'react';
import './Dashboard.css'

export default function Dashboard(props) {
    const { iFrameId, filters, dashboard, widget, settings, hidden, scriptLoaded, type = "iframe" } = props

    const [frame, setFrame] = useState(null)
    const [id, setId] = useState(iFrameId)

    // once script has been loaded create sisense frame object
    useEffect(() => {
        if (scriptLoaded && type !== "iframe") {
            if (frame == null) {   
                if(window['sisense.embed'] != null){                
                const { SisenseFrame } = window['sisense.embed']
                const sisenseFrame = new SisenseFrame({
                    url: process.env.REACT_APP_SisenseURI,
                    dashboard: dashboard,
                    settings: settings,
                    element: document.getElementById(id),
                    widget: widget
                });

                if (filters.length > 0) {
                    sisenseFrame.dashboard.applyFilters(filters)
                }

                sisenseFrame.render(document.getElementById(id), hidden)
                    .then(() => {
                        setId(sisenseFrame._id)
                        setFrame(sisenseFrame)
                    })
                }
            }
        }
    }, [scriptLoaded])

    // On filter updates re-render
    useEffect(() => {
        if (frame != null) {
            console.log("filters",filters)
            frame.dashboard.applyFilters(filters)
            frame.render(document.getElementById(id), hidden)
        }
    }, [filters])

    const getFilterString = () => {
        if(filters.length > 0){
            return encodeURIComponent(JSON.stringify(filters))
        }
    }


    const iframeURL = `${process.env.REACT_APP_SisenseURI}/app/main#/dashboards/${dashboard}/widgets/${widget}?embed=true`;
    // const iframeURL = `${process.env.REACT_APP_SisenseURI}/app/main#/dashboards/${dashboard}/widgets/${widget}?embed=true&filter=${getFilterString()}`;
   
    if ( iFrameId=="sisense-aging-frame") {
        // return <iframe width="100%" height='85%' id={id} className="sisense-dashboard" />
        return <iframe width="100%" height='85%' frameBorder="0" id={id} className="sisense-dashboard"></iframe>
    }
    else if(type === "iframe") {
        return  <iframe width="100%" height='100%' frameBorder="0" src={iframeURL}></iframe>
    }
    else{
        return  <iframe id={id} className="sisense-dashboard" />
    }
}