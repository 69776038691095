import React from "react";
import axios from "axios";
import "./ScreenHeader.css";
import MainLogo from "../../assets/MHA-NetRx-Logo.svg";
import { Col, Navbar } from "react-bootstrap";
import { Organization } from "./Organization";
import { UserAvatar } from "./UserAvatar";
import { Notifications } from "./Notifications";
import { Link } from "react-router-dom";
import { Popover, Overlay, Dropdown } from "react-bootstrap";
import { useState, useRef } from "react";
import {
  Base64ToArrayBuffer,
  SaveByteArray,
} from "../../../helpers/Base64ArrayTranslator";

import { useLocation } from "react-router-dom";

export function ScreenHeader(props) {
  const { user, auth } = props;
  const { given_name, family_name } = user;
  const [show, setShow] = useState(false);
  const target = useRef(null);

  let isAdminOnlyPage = false;
  let adminOnlyPages = ["/AdminExportBatchQueueMaintenance"];

  //NOTE: Based on the pathname, we can disable the header location
  let location = useLocation();

  if (adminOnlyPages.includes(location.pathname)) {
    isAdminOnlyPage = true;
  }

  async function dowloadHelpfile(type) {
    const accessToken = await props.auth.getAccessToken();
    let files = await axios.get("api/UserProgram/GetHelpFiles", {
      params: {
        type: type,
      },
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    var dataAsArray = Base64ToArrayBuffer(files.data.fileStream);
    SaveByteArray(type + ".pdf", dataAsArray);
  }

  const popover = (
    <Popover className="popover-basic">
      <Dropdown.Item
        eventKey="1"
        onClick={() => dowloadHelpfile("Metric-Rx® User Guide")}
      >
        <i className="fa fa-book" /> Metric-Rx® User Guide
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={() => dowloadHelpfile("RecRx User Guide")}
      >
        <i className="fa fa-book" /> RecRx User Guide
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        onClick={() => dowloadHelpfile("Metric-Rx® Best Practices")}
      >
        <i className="fa fa-bookmark-o" /> Metric-Rx® Best Practices
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="4"
        onClick={() => dowloadHelpfile("RecRx Best Practices")}
      >
        <i className="fa fa-bookmark-o" /> RecRx Best Practices
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="5"
        onClick={() => dowloadHelpfile("Metric-Rx® FAQ")}
      >
        <i className="fa fa-question-circle-o" /> Metric-Rx® FAQ
      </Dropdown.Item>
      <Dropdown.Item eventKey="6" onClick={() => dowloadHelpfile("RecRx FAQ")}>
        <i className="fa fa-question-circle-o" /> RecRx FAQ
      </Dropdown.Item>
      <Dropdown.Item eventKey="7" onClick={() => dowloadHelpfile("Release Notes")}>
        <i className="fa fa-book" /> Release Notes
      </Dropdown.Item>
    </Popover>
  );

  return (
    <Navbar className="no-gutters" expand="lg" id="screen-header">
      <Col className="sm-4 no-gutters">
        <Link to="/">
          <Navbar.Brand>
            <img src={MainLogo} width="210" height="80" alt="logo" />
          </Navbar.Brand>
        </Link>
      </Col>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="no-gutters" id="basic-navbar-nav">
        <Col
          className="no-gutters action-link-with-icon"
          id="organization-action-link"
        >
        {isAdminOnlyPage ? null : <Organization />}
          {/* <Organization /> */}
        </Col>
        <Col className="no-gutters" id="screen-header-action-link-group">
          <span
            onClick={() => setShow(!show)}
            className="action-link-with-icon"
          >
            <i ref={target} className="fa fa-info-circle" />
            Help Center
            <Overlay
              target={target.current}
              show={show}
              placement="bottom"
              rootClose
              onHide={() => setShow(!show)}
            >
              {popover}
            </Overlay>
          </span>
        </Col>
        {/* <Col className="col-md-auto">
          <Notifications
            user={{ firstName: given_name, lastName: family_name }}
            auth={auth}
          ></Notifications>
        </Col> */}
        <UserAvatar
          user={{ firstName: given_name, lastName: family_name }}
          auth={auth}
        />
      </Navbar.Collapse>
    </Navbar>
  );
}
