import React from "react";
import { FormatToCurrency } from "../../../helpers/MoneyHelpers";
import './CustomKendoStyles.css'

function MoneyCell(props, options = null) {
    const isNegative = props.dataItem[props.field] < 0;
    const formattedNumber = FormatToCurrency(props.dataItem[props.field]);
    let classCustomName = (isNegative ? "negativeCurrency" : "positiveCurrency");
    if (props.tdProps) {
        classCustomName = props.tdProps.className + " " + classCustomName;
    }
    if (options && options.onClick) {
        return (
            <td
                {...props.tdProps}
                className={classCustomName}
                style={{ ...props.style }}
            >
                <a
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                    onClick={options.onClick}>
                    {formattedNumber}
                </a>
            </td>
        );
    }
    else {
        return (
            <td
                {...props.tdProps}
                className={classCustomName}
                style={{ ...props.style }}
            >
                {formattedNumber}
            </td>
        );
    }

};


function AggregateMoneyFooterCell(props, options = null) {
    let displayAggregateValue = 0;
    let mainGridAggregates = null;
    if (options && options.aggregateData) {
        mainGridAggregates = options.aggregateData;
        if (mainGridAggregates[props.field]) {
            displayAggregateValue = mainGridAggregates[props.field].sum;
        }
    }
    else {
        throw new Error("No aggregate data provided");
        //console.log("this failed");
    }

    const isNegative = displayAggregateValue < 0;
    const classCustomName = props.tdProps.className + " " + (isNegative ? "negativeCurrency" : "positiveCurrency");
    const formattedNumber = FormatToCurrency(parseFloat(displayAggregateValue));

    //NOTE TODO: I think the props.tdProps and props.style here creates some frontend warnings?
    //I also think there needs to be some sort of key here?
    if (options && options.onClick) {
        return (
            <td
                key={props.field + "-Aggregate"}
                // {...props.tdProps}
                className={classCustomName}
                style={{ ...props.style }}
            >
                <a
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                    onClick={options.onClick}>
                    {formattedNumber}
                </a>
            </td>
        );
    }
    else {
        return (
            <td
                key={props.field + "-Aggregate"}
                // {...props.tdProps}
                className={classCustomName}
                style={{ ...props.style }}
            >
                {formattedNumber}
            </td>
        );
    }

};

function AggregateNumberFooterCell(props, options = null) {
    let displayAggregateValue = 0;
    let mainGridAggregates = null;
    if (options && options.aggregateData) {
        mainGridAggregates = options.aggregateData;
        if (mainGridAggregates[props.field]) {
            displayAggregateValue = mainGridAggregates[props.field].sum;
        }
    }
    else {
        console.log("this failed");
    }

    const classCustomName = props.tdProps.className;
    const formattedNumber = displayAggregateValue;
    if (options && options.onClick) {
        return (
            <td
                {...props.tdProps}
                className={classCustomName}
                style={{ ...props.style }}
            >
                <a
                    style={{
                        cursor: "pointer",
                        textDecoration: "underline"
                    }}
                    onClick={options.onClick}>
                    {formattedNumber}
                </a>
            </td>
        );
    }
    else {
        return (
            <td
                {...props.tdProps}
                className={classCustomName}
                style={{ ...props.style }}
            >
                {formattedNumber}
            </td>
        );
    }

};



export { MoneyCell, AggregateMoneyFooterCell, AggregateNumberFooterCell };
