import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";

import { DateRangeForm } from "./DateRangeForm";
import {
  Row,
  Col,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import ErrorHandler from "./../../core/ErrorHandler";
import { DetailsModal } from "../../grid/DetailsModal";
import { LoadingSpinner } from "./../../layout/LoadingSpinner";
import Accordion from "../../Accordion";
import { IgnoreModal } from "../SharedComponents/IgnoreModal";
import { IsNullOrWhitespace } from "../../../helpers/StringHelpers";
import SingleSelectDropdown from "../../SingleSelectDropdown";

import SharedMainGrid from "../../grid/Kendo/SharedMainGrid";
import IgnoreOptions from "../SharedComponents/IgnoreOptions";

import GridExport from "../../export/GridExport";

import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { process, aggregateBy } from "@progress/kendo-data-query";
// import 'font-awesome/css/font-awesome.min.css';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { getter, ZIndexContext } from "@progress/kendo-react-common";

import {
  MoneyCell,
  AggregateMoneyFooterCell,
  AggregateNumberFooterCell,
} from "../../grid/Kendo/CustomKendoGridCells";

import {DefaultColumnWidth, DefaultDateColumnWidth, DefaultBooleanOrShortWidthColumnWidth, DefaultNoFilterColumnWidth} from "../../grid/Kendo/KendoGridAndColumnConstants";

export function DateRange(props) {
  const INITIAL_DETAILS_GRID_DATA_STATE = { skip: 0, take: 50 };
  const DETAILS_SELECTED_FIELD = "SelectedClaimId";
  const DETAILS_DATA_ITEM_KEY = "claimId";
  const idGetter = getter(DETAILS_DATA_ITEM_KEY);

  const { passedProps } = props;
  const user = passedProps.user.email;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});

  const [submittedFormData, setSubmittedFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showIgnoreModal, setShowIgnoreModal] = useState(false);
  const [ignoreError, setIgnoreError] = useState({});
  const [selectedIgnoreOption, setSelectedIgnoreOption] = useState("");
  const [ignoreNote, setIgnoreNote] = useState("");

  const [mainGridData, setMainGridData] = useState([]);
  const [mainGridAggregates, setMainGridAggregates] = useState([]);
  const [mainGridfilter, setMainGridFilter] = useState([]);

  const [detailError, setDetailError] = useState({});
  const [detailsGridData, setDetailsGridData] = useState([]);
  const [detailsSummaryData, setDetailsSummaryData] = useState([]);
  const [detailsGridTitle, setDetailsGridTitle] = useState("");

  const [selectedDetailsState, setSelectedDetailsState] = useState({});
  const [detailsGridAggregates, setDetailsGridAggregates] = useState([]);
  const [detailsGridDataState, setDetailsGridDataState] = useState({});
  const [detailsGridResultState, setDetailsGridResultState] =
    useState(detailsGridData);

  const [selectedNcpdpIgnoreModalText, setSelectedNcpdpIgnoreModalText] =
    useState("");
  const [selectedRxIgnoreModalText, setSelectedRxIgnoreModalText] =
    useState("");
  const [selectedClid, setSelectedClid] = useState("");
  const [selectedDetailsProps, setSelectedDetailsProps] = useState("");
  const [
    ignoreMultipleSelectedClaimsInDetailModal,
    setIgnoreMultipleSelectedClaimsInDetailModal,
  ] = useState(false);

  const [exportData, setExportData] = useState([]);
  const [exportColumns, setExportColumns] = useState([]);

  const sendAggregatesToParent = (aggregatesFromSharedGrid) => {
    setMainGridAggregates(aggregatesFromSharedGrid.data);
    setMainGridFilter(aggregatesFromSharedGrid.filter);
  };

  const _container = React.useRef();
  const _detailsGrid = React.useRef();
  const _detailsExport = React.useRef(null);

  // const excelDetailsGridExport = () => {
  //   let filterAndSortSettings = {};
  //   filterAndSortSettings.filter = detailsGridDataState.filter;
  //   filterAndSortSettings.sort = detailsGridDataState.sort;

  //   let processedData = process(detailsGridData, filterAndSortSettings);
  //   _detailsExport.current.save(processedData, _detailsGrid.current.columns);
  // };

  useEffect(() => {
    let processedData = process(detailsGridData, detailsGridDataState);

    setDetailsGridResultState(processedData);
    setDetailsGridDataState(INITIAL_DETAILS_GRID_DATA_STATE);
  }, [detailsGridData]);

  //NOTE: If the details modal grid data changes, then this is what kicks in
  useEffect(() => {
    let processedData = process(detailsGridData, detailsGridDataState);

    /*
    NOTE TODO: This is sort of okay as long as we don't have paging, but when we add paging it creates a problem
    SEE HOW WE'RE PASSING "processedData.data" and not "processedData"?
    This mostly has to do with the selection logic below needing to do a "map()" call for the Kendo selectable logic
    */
    setDetailsGridResultState(processedData);
    setDetailsGridDataState(INITIAL_DETAILS_GRID_DATA_STATE);
  }, [detailsGridData]);

  useEffect(() => {
    let aggregates = {};
    let filterSettings = {};

    filterSettings.filter = detailsGridDataState.filter;
    let filteredDetailsGridData = process(detailsGridData, filterSettings);

    if (filteredDetailsGridData.data) {
      aggregates = aggregateBy(filteredDetailsGridData.data, [
        { aggregate: "sum", field: "adjudicatedCoPay" },
        { aggregate: "sum", field: "promiseToPay" },
        { aggregate: "sum", field: "remittancePaid" },
        { aggregate: "sum", field: "outstanding" },
      ]);
    }

    setDetailsGridAggregates(aggregates);

    setExportData(filteredDetailsGridData.data);

    if (_detailsGrid.current && _detailsGrid.current.columns)
      setExportColumns(_detailsGrid.current.columns);
  }, [detailsGridResultState]);

  useEffect(() => {
    let processedData = process(
      detailsGridData.map((item) => ({
        ...item,
        [DETAILS_SELECTED_FIELD]: selectedDetailsState[idGetter(item)],
      })),
      detailsGridDataState
    );

    setDetailsGridResultState(processedData);
  }, [selectedDetailsState]);

  const onDetailsGridDataStateChange = (event) => {
    setDetailsGridDataState(event.dataState);

    let processedData = process(
      detailsGridData.map((item) => ({
        ...item,
        [DETAILS_SELECTED_FIELD]: selectedDetailsState[idGetter(item)],
      })),
      event.dataState
    );

    setDetailsGridResultState(processedData);
  };

  const onDetailSelectionChange = useCallback(
    (event) => {
      if (event.startColIndex == 0) {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedDetailsState,
          dataItemKey: DETAILS_DATA_ITEM_KEY,
        });
        setSelectedDetailsState(newSelectedState);
      }
    },
    [selectedDetailsState]
  );

  const onDetailHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target;
      const checked = checkboxElement.checked;
      const newSelectedState = {};
      if (detailsGridData.length > 0) {
        detailsGridData.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
      }
      setSelectedDetailsState(newSelectedState);
    },
    [detailsGridData, selectedDetailsState]
  );

  function getDateRangeDetails(props) {
    try {
      setDetailsGridTitle(props.dataItem["Payer"] + " Total Outstanding");

      let accessToken = passedProps.auth.getAccessToken();

      let response = axios
        .get("api/AgingDetails/GetByDateRangeAging", {
          params: {
            user: user,
            mmids: submittedFormData.mmid,
            AgeAsOf: null,
            PBID: JSON.stringify(props.dataItem["PBID"]),
            fromDate: submittedFormData.fromDate,
            toDate: submittedFormData.toDate,
            dateType: submittedFormData.dateType,
            outstandingType: submittedFormData.reportType,
            payer: props.dataItem["Payer"],
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          onSuccess(response);
        })
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function getAggregateDateRangeDetails() {
    try {
      setDetailsGridTitle("Total Outstanding Aggregate");

      let pushPBID = [];
      if (mainGridfilter == null || mainGridfilter == undefined) {
        for (var count = 0; count < mainGridData.length; count++) {
          pushPBID.push(mainGridData[count].PBID);
        }
      } else {
        let filterSettings = {};
        filterSettings.filter = mainGridfilter;
        var filterResult = process(mainGridData, filterSettings).data;
        for (var count = 0; count < filterResult.length; count++) {
          pushPBID.push(filterResult[count].PBID);
        }
      }
      if (pushPBID.length == 0) {
        return;
      }
      let accessToken = passedProps.auth.getAccessToken();

      let response = axios
        .get("api/AgingDetails/GetByDateRangeAging", {
          params: {
            user: user,
            mmids: submittedFormData.mmid,
            AgeAsOf: null,
            PBID: JSON.stringify(pushPBID).replace("[", "").replace("]", ""),
            fromDate: submittedFormData.fromDate,
            toDate: submittedFormData.toDate,
            dateType: submittedFormData.dateType,
            outstandingType: submittedFormData.reportType,
            //payer: props.dataItem["Payer"],
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          onSuccess(response);
        })
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  const RxCountCell = (props) => {
    if (submittedFormData.reportType === "pending") {
      let onCellClick = () => {
        // alert("see this");
        // console.log(props);
        setIsLoading(true);
        setSelectedDetailsProps(props);
        getDateRangeDetails(props);
      };

      return (
        <td>
          <a
            onClick={onCellClick}
            style={{
              color: "#015CAB",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {props.children}
          </a>
        </td>
      );
    } else {
      return (
        <td>
          <a>{props.children}</a>
        </td>
      );
    }
  };

  const TotalOutstandingMoneyCell = (props) => {
    if (submittedFormData.reportType === "pending") {
      return MoneyCell(props);
    }
    else {
      let onCellClick = () => {
        setIsLoading(true);
        setSelectedDetailsProps(props);
        getDateRangeDetails(props);
      };

      let options = {
        onClick: onCellClick,
      };

      return MoneyCell(props, options);
    }
  };

  const AggregateMainGridMoneyFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const AggregateMainGridMoneyFooterCellWithEvent = (props) => {
    let onCellClick = () => {
      setIsLoading(true);
      getAggregateDateRangeDetails();
    };

    var options = {
      aggregateData: mainGridAggregates,
      onClick: onCellClick
    };

    return AggregateMoneyFooterCell(props, options);
  };

  const AggregateMainGridNumberFooterCell = (props) => {
    var options = {
      aggregateData: mainGridAggregates
    };

    return AggregateNumberFooterCell(props, options);
  };

  const AggregateMoneyDetailFooterCell = (props) => {
    var options = {
      aggregateData: detailsGridAggregates
    };

    return AggregateMoneyFooterCell(props, options);
  };
  
  let CustomIgnoreDetailCell = (props) => {
    return (
      <td
        {...props.tdProps}
        colSpan={1}
        style={{
          color: "#015CAB",
        }}
      >
        <a
          style={{
            cursor: "pointer",
          }}
          onClick={() => ignoreDetailCellClick(props)}
        >
          Ignore
        </a>
      </td>
    );
  };

  //NOTE: This is the NEW Ignore detail for the Kendo grid
  function ignoreDetailCellClick(props) {
    let dataRow = props.dataItem;

    setIgnoreMultipleSelectedClaimsInDetailModal(false);
    setSelectedNcpdpIgnoreModalText(dataRow.ncpdp);
    setSelectedRxIgnoreModalText(dataRow.rxNumber);
    setSelectedClid(dataRow.claimId);
    setIgnoreNote("");
    setSelectedIgnoreOption(null);
    setShowIgnoreModal(true);
  }

  function handleIgnoreMultipleSelectedClaims() {
    setIgnoreMultipleSelectedClaimsInDetailModal(true);
    setSelectedNcpdpIgnoreModalText("All Selected");
    setSelectedRxIgnoreModalText("All Selected");
    setSelectedIgnoreOption(null);
    setIgnoreNote("");
    setShowIgnoreModal(true);
  }

  //Called when user clicks save in ignore details modal
  async function handleSaveIgnoreClick() {
    if (!selectedIgnoreOption) {
      return alert("Please Select an Ignore Code to Save!");
    }

    setShowIgnoreModal(false);

    //NOTE: If there are any values under selectedDetailState, then this variable should become false
    let isIgnoringSingleClaim = true;

    if (ignoreMultipleSelectedClaimsInDetailModal) {
      isIgnoringSingleClaim = false;
    }

    //NOTE: This should cover most cases, like when a single claim is ignored
    if (isIgnoringSingleClaim) {
      try {
        setIsLoading(true);
        const accessToken = await passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          IgnoreId: selectedIgnoreOption.toString(),
          IgnoreNote: ignoreNote,
          ClaimIds: selectedClid.toString(),
        };
        const response = await axios.get("api/AgingDetails/IgnoreClaims", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        updateDetailsGridAfterIgnore(response);
      } catch (e) {
        setIgnoreError({ status: 501, Message: "" });
        setShowIgnoreModal(true);
      }
    } else {
      var detailStateKeys = Object.keys(selectedDetailsState);

      var getOnlyTrueKeys = detailStateKeys.filter(function (key) {
        return selectedDetailsState[key] === true;
      });

      //NOTE: Get all clids that are being ignored
      var strClids = "";
      getOnlyTrueKeys.forEach(function (key) {
        strClids += key + ",";
      });

      try {
        setIsLoading(true);
        const accessToken = await passedProps.auth.getAccessToken();

        let parameters = {
          user: user,
          IgnoreId: selectedIgnoreOption.toString(),
          IgnoreNote: ignoreNote,
          ClaimIds: strClids,
        };
        const response = await axios.get("api/AgingDetails/IgnoreClaims", {
          params: parameters,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        updateDetailsGridAfterIgnore(response);
      } catch (e) {
        setIgnoreError({ status: 501, Message: "" });
        setShowIgnoreModal(true);
      }
    }
  }

  //NOTE: I think this basically "removes a specific item on the grid visually while the ignore action is taking place"
  function updateDetailsGridAfterIgnore(response) {
    if (response.config.params.ClaimIds) {
      getDateRangeDetails(selectedDetailsProps);
    }
    setIgnoreActionCompletedMessage();
  }

  function setIgnoreActionCompletedMessage() {
    setDetailError({
      status: 200,
      Message: "Selected items have been queued to be ignored!",
    });
    setTimeout(() => {
      setDetailError({});
    }, 3600);
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/Aging/GetCurrentAging":
        presentGridData(response.data);
        break;
      case "api/AgingDetails/GetByCurrentAging":
        presentGridDetailData(response);
        break;
      default:
        break;
    }
    setIsLoading(false);
  }

  //////////
  function handleFormSubmit(e) {
    setIsLoading(true);
    setSubmittedFormData(e);
    getDateRangeData(e);
  }

  function getDateRangeData(e) {
    let accessToken = passedProps.auth.getAccessToken();

    try {
      let response = axios
        .get(`api/Aging/GetDateRangeAgingSummary`, {
          params: {
            user: user,
            mmids: e.mmid,
            dateType: e.dateType,
            fromDate: e.fromDate,
            toDate: e.toDate,
            reportType: e.reportType,
            includeClaimsWithNoPayer: e.includeClaimsWithNoPayer,
          },
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then(onSuccess)
        .catch(onFailure);
    } catch (exception) {
      onFailure(exception);
    }
  }

  function onSuccess(response) {
    switch (response.config.url) {
      case "api/Aging/GetDateRangeAgingSummary":
        presentGridData(response.data);
        break;
      case "api/AgingDetails/GetByDateRangeAging":
        presentGridDetailData(response);
        break;
      default:
        break;
    }
    setIsLoading(false);
  }

  function onFailure(error) {
    console.log(error);
    switch (error.response.config.url || error.config.url) {
      case "api/Aging/GetDateRangeAgingSummary":
        setError(error.response);
        // setColumns([]);
        setMainGridData([]);
        break;
      case "api/AgingDetails/GetByDateRangeAging":
        setDetailError(error.response);
        // setDetailsSummaryColumns([]);
        setDetailsSummaryData([]);
        // setDetailColumns([]);
        setDetailsGridData([]);
        setShowModal(true);
        break;
      case "api/AgingDetails/IgnoreClaims":
        setIgnoreError(error.response);
        setShowIgnoreModal(true);
        break;
      default:
        setError(error.response);
        // setColumns([]);
        setMainGridData([]);
        break;
    }
    setIsLoading(false);
    return;
  }

  function presentGridData(data) {
    if (data.length > 0) {
      setError({});
    } else {
      setError({ status: 201, Message: "No data found for given params" });
    }
    setMainGridData(data);
  }

  function presentGridDetailData(response) {
    let detailSummaryData = [];

    if (response.data.length > 0) {
      let totalAdjudicated = 0;
      let totalPaid = 0;
      for (let i = 0; i < response.data.length; i++) {
        totalAdjudicated += response.data[i].promiseToPay;
        totalPaid += response.data[i].remittancePaid;
      }

      detailSummaryData.push({
        Payer: response.config.params.payer,
        ClaimCount: response.data.length,
        Adjudicated: totalAdjudicated,
        Paid: totalPaid,
        Outstanding: (totalAdjudicated - totalPaid),
      });

      setDetailError({});
    } else {
      setDetailError({
        status: 201,
        Message: "No data found for given params",
      });
    }

    let detailsDataWithCleanedDates = response.data.map((t) => {
      return {
        ...t,
        dispensedDate: IsNullOrWhitespace(t.dispensedDate)
          ? ""
          : new Date(Date.parse(t.dispensedDate)),
        transactionDate: IsNullOrWhitespace(t.transactionDate)
          ? ""
          : new Date(Date.parse(t.transactionDate)),
      };
    });

    setDetailsGridData(detailsDataWithCleanedDates);
    setDetailsSummaryData(detailSummaryData);

    setShowModal(true);
  }

  function setIgnoreActionCompletedMessage() {
    setDetailError({
      status: 200,
      Message: "Selected items have been queued to be ignored!",
    });
    setTimeout(() => {
      setDetailError({});
    }, 3600);
  }

  // function getNewRowSelectedValue(rows) {
  //   if (rows.length > 1) {
  //     return !rows.every((x) => {
  //       return x.selected === true;
  //     });
  //   }
  //   return !rows[0].selected;
  // }

  return (
    <div className="feature-tab" id="Date-Range">
      <LoadingSpinner
        isDataLoading={isLoading}
        controlsName={"agingDateRange"}
      />
      <ErrorHandler
        error={error}
        onClose={() => {
          setError({});
        }}
      />
      <Accordion defaultExpanded label="Search & Filter">
        <DateRangeForm handleFormSubmit={handleFormSubmit} />
      </Accordion>

      <div ref={_container}>
        <Row>
          <Col md={10} className="tight-grid multi-line-filter">
            <SharedMainGrid
              data={mainGridData}
              aggregateColumnSettings={[
                { aggregate: "sum", field: "Adjudicated" },
                { aggregate: "sum", field: "RxCount" },
                { aggregate: "sum", field: "Paid" },
                { aggregate: "sum", field: "TotalOutstanding" },
              ]}
              sendAggregatesParentCallback={sendAggregatesToParent}
              exportFileNamePrefix={"DateRangeAging"}
            >
              <GridColumn
                key={"Payer"}
                field={"Payer"}
                title={"Payer"}
                width={DefaultColumnWidth()}
              />
              <GridColumn
                key={"RxCount"}
                field={"RxCount"}
                title={"Rx Count"}
                filter={"numeric"}
                width={DefaultColumnWidth()}
                cells={{
                  data: RxCountCell,
                  footerCell: AggregateMainGridNumberFooterCell,
                }}
                footerCell={AggregateMainGridNumberFooterCell}
              />
              <GridColumn
                key={"Adjudicated"}
                field={"Adjudicated"}
                title={"Adjudicated"}
                filter={"numeric"}
                width={DefaultColumnWidth()}
                cells={{
                  data: MoneyCell,
                  footerCell: AggregateMainGridMoneyFooterCell,
                }}
                footerCell={AggregateMainGridMoneyFooterCell}
              />
              <GridColumn
                key={"Paid"}
                field={"Paid"}
                title={"Paid"}
                filter={"numeric"}
                width={DefaultColumnWidth()}
                cells={{
                  data: MoneyCell,
                  footerCell: AggregateMainGridMoneyFooterCell,
                }}
                footerCell={AggregateMainGridMoneyFooterCell}
              />
              <GridColumn
                key={"TotalOutstanding"}
                field={"TotalOutstanding"}
                title={"Outstanding"}
                filter={"numeric"}
                width={DefaultColumnWidth()}
                cells={{
                  data: TotalOutstandingMoneyCell,
                  footerCell: AggregateMainGridMoneyFooterCellWithEvent,
                }}
                footerCell={AggregateMainGridMoneyFooterCellWithEvent}
              />
            </SharedMainGrid>
          </Col>
        </Row>
      </div>

      <DetailsModal
        title="Payer Summary"
        show={showModal}
        handleClose={() => {
          setIsLoading(true);
          getDateRangeData(submittedFormData);
          return (
            setShowModal(false),
            setSelectedDetailsState({}),
            _container.current.focus()
          );
        }}
      >
        <ErrorHandler
          error={detailError}
          onClose={() => {
            setDetailError({});
          }}
        />
        <Row>
          <Col className="tight-grid multi-line-filter">
            <Grid
              style={{
                maxHeight: "10em",
              }}
              data={detailsSummaryData}
            >
              {detailsSummaryData[0] && detailsSummaryData[0].Payer ? (
                <GridColumn
                  key={"Payer"}
                  field={"Payer"}
                  title={"Payer"}
                  width={DefaultColumnWidth()}
                />
              ) : null}
              <GridColumn
                key={"ClaimCount"}
                field={"ClaimCount"}
                title={"Claim Count"}
                width={DefaultColumnWidth()}
              />
              <GridColumn
                key={"Adjudicated"}
                field={"Adjudicated"}
                title={"Adjudicated"}
                width={DefaultColumnWidth()}
                cells={{ data: MoneyCell }}
              />
              <GridColumn
                key={"Paid"}
                field={"Paid"}
                title={"Paid"}
                width={DefaultColumnWidth()}
                cells={{ data: MoneyCell }}
              />
              <GridColumn
                key={"Outstanding"}
                field={"Outstanding"}
                title={"Outstanding"}
                width={DefaultColumnWidth()}
                cells={{ data: MoneyCell }}
              />
            </Grid>
          </Col>
        </Row>

        <br />
        <h2>Aging Detail</h2>

        <h4>{detailsGridTitle}</h4>

        <ZIndexContext.Provider value={10003}>
          <ExcelExport ref={_detailsExport}>
            <GridExport
              exportData={exportData}
              exportDataColumns={exportColumns}
              exportFileNamePrefix={"DateRangeAgingDetails"}
            />

            <Row>
              <Col className="tight-grid multi-line-filter">
                <Grid
                  style={{
                    maxHeight: "30em",
                  }}
                  data={detailsGridResultState}
                  dataItemKey={DETAILS_DATA_ITEM_KEY}
                  selectedField={DETAILS_SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                  }}
                  onSelectionChange={onDetailSelectionChange}
                  onHeaderSelectionChange={onDetailHeaderSelectionChange}
                  onDataStateChange={onDetailsGridDataStateChange}
                  filterable={true}
                  pageable={true}
                  sortable={true}
                  {...detailsGridDataState}
                >
                  <GridToolbar>
                    <button
                      title="Ignore Selected Claims"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={handleIgnoreMultipleSelectedClaims}
                    >
                      Ignore Selected Claims
                    </button>
                  </GridToolbar>
                  <GridColumn
                    field={DETAILS_SELECTED_FIELD}
                    width="50px"
                    filterable={false}
                    headerSelectionValue={
                      detailsGridData.findIndex(
                        (item) => !selectedDetailsState[idGetter(item)]
                      ) === -1
                    }
                  />
                  <GridColumn
                    key={""}
                    field={""}
                    title={"Ignore"}
                    filterable={false}
                    width={DefaultNoFilterColumnWidth()}
                    cells={{
                      data: CustomIgnoreDetailCell,
                    }}
                  />
                  <GridColumn
                    key={"ncpdp"}
                    field={"ncpdp"}
                    title={"NCPDP"}
                    width={DefaultBooleanOrShortWidthColumnWidth()}
                  />
                  <GridColumn
                    key={"rxNumber"}
                    field={"rxNumber"}
                    title={"Rx #"}
                    filter={"text"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"dispensedDate"}
                    field={"dispensedDate"}
                    title={"Dispensed Date"}
                    filter={"date"}
                    format="{0:d}"
                    width={DefaultDateColumnWidth()}
                  />
                  <GridColumn
                    key={"transactionDate"}
                    field={"transactionDate"}
                    title={"Transaction Date"}
                    filter={"date"}
                    format="{0:d}"
                    width={DefaultDateColumnWidth()}
                  />
                  <GridColumn
                    key={"bin"}
                    field={"bin"}
                    title={"BIN"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"pcn"}
                    field={"pcn"}
                    title={"PCN"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"claimNumber"}
                    field={"claimNumber"}
                    title={"Claim #"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"cardHolderId"}
                    field={"cardHolderId"}
                    title={"Cardholder ID"}
                    width={DefaultColumnWidth()}
                  />
                  <GridColumn
                    key={"adjudicatedCoPay"}
                    field={"adjudicatedCoPay"}
                    title={"Copay"}
                    filter={"numeric"}
                    width={DefaultColumnWidth()}
                    cells={{
                      data: MoneyCell,
                      footerCell: AggregateMoneyDetailFooterCell,
                    }}
                    footerCell={AggregateMoneyDetailFooterCell}
                  />
                  <GridColumn
                    key={"promiseToPay"}
                    field={"promiseToPay"}
                    title={"Promise to Pay"}
                    filter={"numeric"}
                    width={DefaultColumnWidth()}
                    cells={{
                      data: MoneyCell,
                      footerCell: AggregateMoneyDetailFooterCell,
                    }}
                    footerCell={AggregateMoneyDetailFooterCell}
                  />
                  <GridColumn
                    key={"remittancePaid"}
                    field={"remittancePaid"}
                    title={"Paid"}
                    filter={"numeric"}
                    width={DefaultColumnWidth()}
                    cells={{
                      data: MoneyCell,
                      footerCell: AggregateMoneyDetailFooterCell,
                    }}
                    footerCell={AggregateMoneyDetailFooterCell}
                  />
                  <GridColumn
                    key={"outstanding"}
                    field={"outstanding"}
                    title={"Outstanding"}
                    filter={"numeric"}
                    width={DefaultColumnWidth()}
                    cells={{
                      data: MoneyCell,
                      footerCell: AggregateMoneyDetailFooterCell,
                    }}
                    footerCell={AggregateMoneyDetailFooterCell}
                  />
                </Grid>
              </Col>
            </Row>
          </ExcelExport>
        </ZIndexContext.Provider>
      </DetailsModal>
      <IgnoreModal
        title="Ignore Details"
        show={showIgnoreModal}
        handleClose={() => {
          return (
            setShowIgnoreModal(false),
            setIgnoreMultipleSelectedClaimsInDetailModal(false),
            setDetailsGridDataState({}),
            setDetailsGridData({})
          );
        }}
      >
        <ErrorHandler
          error={ignoreError}
          onClose={() => {
            setIgnoreError({});
          }}
        />
        <Container>
          <Row>
            <Col> NCPDP </Col>
            <Col> Rx #</Col>
          </Row>
          <Row>
            <Col>{selectedNcpdpIgnoreModalText}</Col>
            <Col>{selectedRxIgnoreModalText}</Col>
          </Row>
          <br />
          <Row>
            <Col> Ignore Code </Col>
          </Row>
          <Row md={1}>
            <Col>
              {" "}
              <SingleSelectDropdown
                options={IgnoreOptions}
                highlightSelection={true}
                onChange={(selectedIgnoreOption) => {
                  setSelectedIgnoreOption(selectedIgnoreOption);
                }}
              />{" "}
            </Col>
          </Row>
          <br />
          <Row md={1}>
            <Col>
              <Form.Group controlId="txtIgnoreNote">
                <Form.Label> Comments </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  placeholder="Type your message here..."
                  value={ignoreNote}
                  onChange={(event) => {
                    setIgnoreNote(event.target.value);
                  }}
                >
                  {" "}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                variant="secondary"
                onClick={() => setShowIgnoreModal(false)}
              >
                {" "}
                Cancel{" "}
              </Button>
            </Col>
            <Col>
              <Button
                className="primary-button"
                onClick={handleSaveIgnoreClick}
              >
                {" "}
                Save{" "}
              </Button>
            </Col>
          </Row>
        </Container>
      </IgnoreModal>
    </div>
  );
}
